<template>
  <div>
    <main-title :name="name"></main-title>

    <v-container class="pt-12 pb-12">
      <div class="products">
        <v-divider class="mt-10 mb-10"></v-divider>

        <v-row>
          <v-col
              v-for="product in products"
              cols="12" md="4">
            <v-img
                :src="product.image"
                :lazy-src="product.image"
                aspect-ratio="1"
                class="grey lighten-2"
            >
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
      </div>

      <v-divider class="mt-10 mb-10"></v-divider>


    </v-container>
  </div>
</template>

<script>
import axios from "axios"
  export default {
    data: () => ({
      item: 1,
      name: 'Gallery',
      products: []
    }),
    created() {
      this.loadImages();
    },
    watch: {

    },
    methods: {
      loadImages() {
        let self = this;
        axios.get('/base/products/').then(function(r) {
          self.products = r.data;
          self.$forceUpdate();
        });
      }
    }
  }
</script>